


















import { computed, defineComponent, onMounted, reactive } from "@vue/composition-api";
import { configureScope } from "@sentry/vue";
import { identifyUser } from "@/services/analytics";
import { loadDynamicModules } from "@/store/dynamicModules";
import { RoutesConfig } from "../router/Routes";
import { uiModule } from "@/store/modules/ui";
import { useModule } from "@/store/hooks";
import { userModule } from "../store/modules/user";
import { useRouter } from "../router";
import authModule from "@/store/modules/auth";
import store from "@/store";
import stripe from "@/services/stripe";

export default defineComponent({
  name: "GodMode",
  setup() {
    const { currentRoute, navigate } = useRouter();
    const { mutations } = useModule(authModule);
    const vm = reactive({
      key: "ll123",
      pass: "",
    });
    onMounted(() => {
      if (!userModule.state.user.employee) {
        userModule.state.user.role === "seller" ? navigate(RoutesConfig.SELLER_DASHBOARD.path) : navigate(RoutesConfig.CUSTOMER_DASHBOARD.path);
      }
    });
    const enabled = computed(() => vm.key === vm.pass);
    const uid = computed(() => currentRoute?.value?.params?.uid);
    async function startGodMode() {
      if (enabled.value) {
        identifyUser(uid.value);
        configureScope((scope) => {
          scope.setUser({
            id: uid.value,
          });
        });
        try {
          await Promise.all([loadDynamicModules(store, uid.value), stripe.setup()]);
          mutations.authorize(uid.value);
          mutations.simulate(uid.value);
          window.Intercom && window.Intercom("shutdown");

          await uiModule.actions.fetchProjectCount();
          if (userModule.state.user.role === "seller") {
            uiModule.actions.fetchIncompleteReportsCount();
            uiModule.actions.fetchUnreadNewsMessageCount();
          }
          else {
            await uiModule.actions.fetchTeamCount();
            if (userModule.state.user.role === "parent") uiModule.actions.fetchUnpaidInvoices();
          }
        }
        catch (e) {
          throw new Error(
            "Failed to load dynamic modules despite being authorized",
          );
        }
        userModule.state.user.role === "seller" ? navigate(RoutesConfig.SELLER_DASHBOARD.path) : navigate(RoutesConfig.CUSTOMER_DASHBOARD.path);
      }
    }
    return {
      currentRoute,
      enabled,
      startGodMode,
      uid,
      vm,
    };
  },
});
